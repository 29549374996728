import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';

export const financialDocumentParser = async payload => {
  try {
    const response = await axios.post(
      apiConfig.financialAccounts.endpoints.financial_document_parser,
      payload
    );

    return {
      success: true,
      data: response?.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const multiFinancialDocumentParser = async payload => {
  try {
    const response = await axios.post(
      apiConfig.financialAccounts.endpoints.multi_financial_document_parser,
      payload
    );

    return {
      success: true,
      data: response?.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const getCompaniesFinancialsByCompanyNumber = async company_number => {
  try {
    const response = await axios.get(
      buildUrl(apiConfig.base, apiConfig.financialAccounts.endpoints.getCompaniesFinancialsByCompanyNumber),
      { params: { company_number: company_number } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};
