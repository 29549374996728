import '../SearchOnPerson/SearchOnPerson.css';
import '../../JudgementChecker.css';
import './JudgementCheckerPerson.css';
import checkBusiness from '../../../../assets/images/Documents-bro.svg';
import trustPower from '../../../../assets/images/icons/powered-trust-online.svg';
import { AppContext } from '../../../../context/Context.js';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getCardsByIndex,
  getOfficerLocationString,
} from '../../../../utils/helper.js';
import {
  fontSizeForJudgmentCheckerInput,
  staticCardData,
} from '../../../../utils/common.js';
import FindAddressModal from '../../common/FindAddressModal.jsx';
import { getJudgementAddresses } from '../../../../api/judgementChecker/judgementChecker.js';
import { useLoading } from '../../../../context/LoadingContext/LoadingContext.js';
import showToast from '../../../../utils/showToast.js';
import PoweredByTrustOnline from '../../common/PoweredByTrustOnline/PoweredByTrustOnline.jsx';
import LocalStorageService from '../../../../utils/LocalStorageServices.js';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton.jsx';
import URLService from '../../../../utils/URLService.js';

function JudgementCheckerPerson() {
  const [searchParams] = useSearchParams(); // Extract URL parameters
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const [addresses, setAddresses] = useState([]);
  const [selectedCards, setSelectedCards] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [isDivVisible, setDivVisible] = useState(false);
  const [isManualAddress, setIsManualAddress] = useState(false);
  const { judgementChecker, setJudgementChecker } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [personInfo, setPersonInfo] = useState({
    title: '',
    firstName: '',
    lastName: '',
    postalCode: '',
    houseNumber: '',
    houseName: '',
    street: '',
    town: '',
    fullAddress: '',
    county: '',
  });

  const resetAddressInfo = () => {
    setPersonInfo(prevState => ({
      ...prevState,
      postalCode: '',
      houseNumber: '',
      houseName: '',
      street: '',
      town: '',
      fullAddress: '',
      county: '',
    }));
  };

  // Function to validate input
  const validateInput = (name, value) => {
    if (!value.trim()) {
      // Split camelCase and capitalize each word
      const formattedName = name
        .replace(/([A-Z])/g, ' $1') // Insert a space before each capital letter
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter

      return `${formattedName} is required`;
    }
    return '';
  };

  const handleDivToggle = () => {
    LocalStorageService.setItem('is-address-manual', true);
    setDivVisible(true);
    setErrors({});
  };

  const confirmManualAddress = () => {
    // Perform validation for all fields
    const newErrors = {};
    ['street', 'town', 'county', 'postalCode'].forEach(key => {
      const error = validateInput(key, personInfo[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Update the errors state
      return; // Stop further execution
    }

    setIsManualAddress(true);
    setDivVisible(false);

    if (personInfo) {
      let { postalCode, houseNumber, houseName, street, town, county } =
        personInfo;
      let fullAddressGen =
        houseName +
        ' ' +
        houseNumber +
        ' ' +
        street +
        ', ' +
        town +
        ', ' +
        county +
        ', ' +
        postalCode;
      setPersonInfo(prevState => {
        return { ...prevState, fullAddress: fullAddressGen };
      });
      setSelectedAddress(fullAddressGen);
    }
  };

  // const handleCardClick = index => {
  //   if (selectedCards.includes(index)) {
  //     setSelectedCards(selectedCards.filter(item => item !== index));
  //   } else {
  //     setSelectedCards([...selectedCards, index]);
  //   }
  // };

  // Event handlers to update user state and validate
  const handleInputChange = e => {
    const { name, value } = e.target;
    // Convert the value to uppercase before setting it
    const updatedValue = value.toUpperCase();

    // Validate input
    const error = validateInput(name, value);

    // Update user state and errors state
    setPersonInfo({ ...personInfo, [name]: updatedValue });
    setErrors({ ...errors, [name]: error });
  };

  // Function to handle continue button click
  const submit = e => {
    const autoClick = autoComplete();

    // Perform validation for all fields
    const newErrors = {};
    ['title', 'firstName', 'lastName', 'postalCode'].forEach(key => {
      const error = validateInput(key, personInfo[key]);
      if (error) {
        newErrors[key] = error;
      }
    });

    if (Object.keys(newErrors).length > 0 && !autoClick) {
      e.preventDefault();
      setErrors(newErrors);
      return;
    }

    if (!personInfo.fullAddress !== '' && !selectedAddress && !autoClick) {
      showToast('warning', 'Please confirm address.');
      return;
    } else {
      navigate(
        !autoClick
          ? '/ccj-dashboard/check-review-person'
          : '/ccj-dashboard/check-review-person?auto-click=true'
      );
    }
  };

  const handleFindAddressUsingPostalCode = async () => {
    try {
      setIsLoading(true);
      LocalStorageService.setItem('is-address-manual', false);
      let { postalCode } = personInfo;
      if (postalCode !== '') {
        let postalAddress;
        setIsManualAddress(false);

        // Check if postal code data exists in localStorage
        const cachedPostalAddress = localStorage.getItem(
          `postalCode-${personInfo.postalCode}`
        );

        if (cachedPostalAddress) {
          postalAddress = JSON.parse(cachedPostalAddress);
        } else {
          postalAddress = await getJudgementAddresses(personInfo.postalCode);
        }

        if (personInfo.postalCode && postalAddress.data?.length > 0) {
          setAddresses(postalAddress.data);
          setShowAddressModal(true);
        } else {
          if (
            postalAddress?.status === 404 ||
            postalAddress.data?.length === 0
          ) {
            showToast('error', 'Address not found');
            setIsLoading(false);
          }
        }
        setIsLoading(false);
      } else {
        setErrors({ postalCode: 'Postal code is required' });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setSelectedAddressHandler = address => {
    setPersonInfo(prevState => {
      return {
        ...prevState,
        fullAddress: address?.envelopeAddress,
        addressObject: address,
      };
    });
    setSelectedAddress(address?.envelopeAddress);
    setShowAddressModal(false);
  };

  const findTitle = inputString => {
    const cleanedInput = inputString.replace(/[^a-z0-9. ]/gi, '').toUpperCase();
    const pattern = /\b(MR\.?|MRS\.?|MISS\.?|MS\.?)\b/;
    const match = cleanedInput.match(pattern);
    return match ? match[0] : '';
  };

  const autoComplete = () => {
    const autoClick = searchParams.get('auto-click') === 'true';
    return autoClick;
  };

  useEffect(() => {
    setJudgementChecker(prev => ({
      ...prev,
      personJudgementChecker: {
        ...prev.personJudgementChecker,
        personInfo: personInfo,
        selectedRegisters: getCardsByIndex(staticCardData, selectedCards),
        type: 'PersonSearch',
      },
    }));
  }, [personInfo, selectedCards]);

  useEffect(() => {
    if (selectedAddress && autoComplete()) {
      submit();
    }
  }, [selectedAddress]);

  useEffect(() => {
    const autoClick = autoComplete();

    if (autoClick === true) {
      let intro = URLService.getParam('intro');
      let address = URLService.getParam('address');
      let introObject = JSON.parse(intro);
      let addressObject = JSON.parse(address);
      if (addressObject && intro) {
        setPersonInfo({
          title: findTitle(introObject?.name),
          firstName: introObject?.name.replace(
            findTitle(introObject?.name),
            ''
          ),
          lastName: '',
          postalCode: '',
          houseNumber: '',
          houseName: '',
          street: '',
          town: '',
          fullAddress: getOfficerLocationString(addressObject),
          county: addressObject?.country,
        });

        setSelectedAddress(getOfficerLocationString(addressObject));
      }
    } else {
      let addressEntryType = LocalStorageService.getItem('is-address-manual');
      if (addressEntryType) {
        setDivVisible(true);
      }

      if (judgementChecker?.personJudgementChecker?.personInfo) {
        setPersonInfo(judgementChecker?.personJudgementChecker?.personInfo);
      }

      const targetDiv = document.getElementById('enter_personal_detail');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      {autoComplete ? (
        ''
      ) : (
        <div className="dashboard-section">
          <div className="main-div">
            <div className="dashboard-content-div">
              {/* powered by Trust online component */}
              <PoweredByTrustOnline
                staticCardData={staticCardData}
                trustPower={trustPower}
              />

              <div className="person-detail-card" id="enter_personal_detail">
                <div className="contennt-div">
                  <div className="heading-2-light">Enter Person’s Details</div>
                  <div className="d-flex align-items-start gap-2">
                    <div className="small-text-bold">Note:</div>
                    <div>
                      <div className="small-text-bold">
                        We can only search using the name and address you
                        provide.
                      </div>
                      <div className="small-text-normal">
                        Please ensure that the details you enter match those
                        when the record was registered.
                      </div>
                    </div>
                  </div>

                  <div className="input-outer-div">
                    <div className="button-text mb-1">Name</div>
                    <div className="small-text-normal">
                      Please enter the name of the Person you wish to search
                    </div>
                    <div className="parallel-input-div">
                      <div className="JudgementCheckerPerson-input">
                        <input
                          className="small-input"
                          placeholder="Title"
                          name="title"
                          value={personInfo.title}
                          onChange={handleInputChange}
                          style={fontSizeForJudgmentCheckerInput()}
                        />
                        <div
                          style={{
                            // minHeight: "50px",
                            margin: '2px',
                            minWidth: '150px',
                          }}
                        >
                          {errors.title && (
                            <div className="error">{errors.title}</div>
                          )}
                        </div>
                      </div>

                      <div className="JudgementCheckerPerson-input">
                        <input
                          placeholder="First Name"
                          name="firstName"
                          value={personInfo.firstName}
                          onChange={handleInputChange}
                          style={fontSizeForJudgmentCheckerInput()}
                        />
                        <div style={{ margin: '2px', minWidth: '150px' }}>
                          {errors.firstName && (
                            <div className="error">{errors.firstName}</div>
                          )}
                        </div>
                      </div>

                      <div className="JudgementCheckerPerson-input">
                        <input
                          placeholder="Last Name"
                          name="lastName"
                          value={personInfo.lastName}
                          onChange={handleInputChange}
                          style={fontSizeForJudgmentCheckerInput()}
                        />
                        <div style={{ margin: '2px', minWidth: '150px' }}>
                          {errors.lastName && (
                            <div className="error">{errors.lastName}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="judgment-checker-person-input-div"
                    className="input-outer-div"
                    style={{ width: '100%' }}
                  >
                    <div className="button-text mb-1">Address</div>
                    <div className="small-text-normal">
                      Please enter the address you wish to search
                    </div>
                    {/* find address buton */}
                    {!selectedAddress && (
                      <>
                        {!isDivVisible && (
                          <div className="parallel-input-div">
                            <div>
                              {' '}
                              <div className="JudgementCheckerPerson-input postal-code">
                                <input
                                  placeholder="Postal Code"
                                  name="postalCode"
                                  value={personInfo.postalCode}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />
                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {errors.postalCode && (
                                    <div className="error">
                                      {errors.postalCode}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className="find-address-btn"
                              onClick={handleFindAddressUsingPostalCode}
                            >
                              Find Address
                            </div>
                            <div>Or</div>
                            <div className="link-text body-text-normal">
                              <u onClick={handleDivToggle}>
                                Manually Enter Address
                              </u>
                            </div>
                          </div>
                        )}
                        {isDivVisible && (
                          <div>
                            <div className="parallel-input-div">
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="House NO."
                                  name="houseNumber"
                                  value={personInfo.houseNumber}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {/* {errors.houseNumber && (
                              <div className="error">
                                {errors.houseNumber}
                              </div>
                            )} */}
                                </div>
                              </div>
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="House Name."
                                  name="houseName"
                                  value={personInfo.houseName}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {/* {errors.houseName && (
                              <div className="error">{errors.houseName}</div>
                            )} */}
                                </div>
                              </div>
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="Street Name"
                                  name="street"
                                  value={personInfo.street}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {errors.street && (
                                    <div className="error">{errors.street}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="parallel-input-div">
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="Town"
                                  name="town"
                                  value={personInfo.town}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {errors.town && (
                                    <div className="error">{errors.town}</div>
                                  )}
                                </div>
                              </div>
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="Country"
                                  name="county"
                                  value={personInfo.county}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {errors.county && (
                                    <div className="error">{errors.county}</div>
                                  )}
                                </div>
                              </div>
                              <div className="JudgementCheckerPerson-input">
                                <input
                                  placeholder="Postal Code"
                                  name="postalCode"
                                  value={personInfo.postalCode}
                                  onChange={handleInputChange}
                                  style={fontSizeForJudgmentCheckerInput()}
                                />

                                <div
                                  style={{ margin: '2px', minWidth: '150px' }}
                                >
                                  {errors.postalCode && (
                                    <div className="error">
                                      {errors.postalCode}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="parallel-input-div">
                              <div
                                className="find-address-btn"
                                onClick={confirmManualAddress}
                              >
                                Confirm Address
                              </div>
                              <div className="find-address-btn"> Or</div>
                              <div
                                className="find-address-btn"
                                onClick={() => {
                                  setErrors({});
                                  setDivVisible(false);
                                  setIsManualAddress(false);
                                }}
                              >
                                Back
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {selectedAddress && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: '24px',
                          padding: '4px',
                          border: '1px dotted #007bff',
                        }}
                      >
                        <div>{selectedAddress}</div>
                        <div style={{ display: 'flex' }}>
                          <button
                            style={{
                              backgroundColor: '#007bff', // Blue color for the edit button
                              color: 'white',
                              padding: '6px 12px',
                              margin: '0 5px',
                              border: 'none',
                              borderRadius: '10px', // Rounded borders
                              cursor: 'pointer',
                              fontSize: '14px', // Font size similar to the continue button
                              boxShadow: '0px 2px 2px rgba(0, 123, 255, 0.25)', // Similar shadow effect
                            }}
                            onClick={() => {
                              if (!isManualAddress) {
                                setShowAddressModal(true);
                              } else {
                                setSelectedAddress(null);
                                setDivVisible(true);
                              }
                            }} // Replace with your actual edit function
                          >
                            Edit
                          </button>
                          <button
                            style={{
                              backgroundColor: '#dc3545', // Red color for the remove button
                              color: 'white',
                              padding: '6px 12px',
                              border: 'none',
                              borderRadius: '10px', // Rounded borders
                              cursor: 'pointer',
                              fontSize: '14px', // Font size similar to the continue button
                              boxShadow: '0px 2px 2px rgba(220, 53, 69, 0.25)', // Similar shadow effect
                            }}
                            onClick={() => {
                              resetAddressInfo();
                              setSelectedAddress(null);
                            }} // Replace with your actual remove function
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="input-outer-div">
                    <div className="small-text-bold">
                      Note:You will still be able to review your searches before
                      payment
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    className="judgment_checker_responsive_image image-res"
                    src={checkBusiness}
                    width={350}
                    alt="Person"
                  />
                </div>
              </div>
              <div
                className="JudgementCheckerPerson-continue"
                // style={{
                //   display: "flex",
                //   justifyContent: "flex-start",
                //   width: "100%",
                //   marginTop: "20px",
                // }}
              >
                <ProbeButton
                  name={'Continue'}
                  backgroundColor={'#0466D1'}
                  borderRadius={'10px'}
                  borderColor={'#blue'}
                  height={'40px'}
                  width={'220px'}
                  onClick={submit}
                  fontColor="#FFF"
                />
              </div>
            </div>
          </div>

          <FindAddressModal
            show={showAddressModal}
            setShowAddressModal={setShowAddressModal}
            addresses={addresses}
            setSelectedAddressHandler={setSelectedAddressHandler}
          />
        </div>
      )}
    </>
  );
}

export default JudgementCheckerPerson;
