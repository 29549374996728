import React, { useEffect, useContext, useState } from 'react';
import '../CheckReviewPerson/SearchOnPerson/SearchOnPerson';
import '../JudgementChecker.css';
import './CheckReviewPerson.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import headerLine from '../../../assets/images/icons/header-line.svg';
import editIcon from '../../../assets/images/ic_sharp-edit.svg';
import removeIcon from '../../../assets/images/fluent_delete-24-filled.svg';
import { AppContext } from '../../../context/Context';
import Payment from '../common/Payment';
import Registers from '../common/Registers';
import { constructSearchData, continueToCheckOut } from '../../../utils/helper';
import { createCheckoutSession } from '../../../api/judgementChecker/judgementChecker';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import OneFinalCheck from '../../../components/OneFinalCheck/OneFinalCheck';
import { triggerGoogleAnalyticsEvent } from '../../../utils/googleAnalyticsEvent';

function CheckReviewPerson() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { judgementChecker, setJudgementChecker, user } =
    useContext(AppContext);
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [linkedToSearch, setLinkedToSearch] = useState(true);
  const [avaibleViaTrust, setAvaibleViaTrust] = useState(true);

  const checkOutPayment = async () => {
    if (!privacyPolicy || !linkedToSearch || !avaibleViaTrust) return;

    await continueToCheckOut(
      setIsLoading,
      createCheckoutSession,
      judgementChecker,
      user
    );
  };

  // going to get your details.
  const continueToGetYourDetail = async () => {
    navigate('/ccj-dashboard/judgement-checker-your-detail');
  };

  const editReport = () => {
    navigate('/ccj-dashboard/judgement-checker-person');
  };

  const removeReport = () => {
    navigate('/ccj-dashboard/judgement-checker');
  };

  const checkoutButtonClicked = () => {
    triggerGoogleAnalyticsEvent('payments', 'checkout_button_clicked');
    const next =
      user && !user?.guest ? checkOutPayment : continueToGetYourDetail;
    next();
  };

  const autoComplete = () => {
    const autoClick = searchParams.get('auto-click') === 'true';
    return autoClick;
  };

  useEffect(() => {
    if (
      judgementChecker &&
      judgementChecker.personJudgementChecker &&
      judgementChecker.personJudgementChecker.personInfo
    ) {
      let streetValue = '';
      let postalCodeValue = '';
      let townValue = '';
      let { title, firstName, lastName } =
        judgementChecker.personJudgementChecker.personInfo;

      if (judgementChecker.personJudgementChecker.personInfo?.addressObject) {
        let { town, postCode, addressLine1 } =
          judgementChecker.personJudgementChecker.personInfo.addressObject
            .address.envelopeAddress;
        streetValue = addressLine1;
        postalCodeValue = postCode;
        townValue = town;
      } else {
        let { town, postalCode, street } =
          judgementChecker.personJudgementChecker.personInfo;
        streetValue = street;
        postalCodeValue = postalCode;
        townValue = town;
      }

      let person = [
        {
          title: title,
          forename: firstName,
          surname: lastName,
        },
      ];

      let address = [
        {
          postcode: postalCodeValue,
          street: streetValue,
          town: townValue,
        },
      ];

      judgementChecker.personJudgementChecker['names'] = person;
      judgementChecker.personJudgementChecker['addresses'] = address;
      const data = constructSearchData(judgementChecker.personJudgementChecker);
      setJudgementChecker(prev => ({
        ...prev,
        personJudgementChecker: {
          ...prev.personJudgementChecker,
          JudgementChecker: data,
          type: 'PersonSearch',
        },
      }));
    }
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        {/* main person information */}
        {judgementChecker &&
          judgementChecker.personJudgementChecker &&
          judgementChecker.personJudgementChecker.personInfo && (
            <div className="dashboard-content-div">
              <div className="first-parallel-div">
                <div className="cards-div card-1">
                  <div className="card-headers-button-text">
                    <img src={headerLine} />
                    {judgementChecker.personJudgementChecker.personInfo.title +
                      ' ' +
                      judgementChecker.personJudgementChecker.personInfo
                        .firstName +
                      ' ' +
                      judgementChecker.personJudgementChecker.personInfo
                        .lastName +
                      ' ' +
                      '- £8.50'}
                  </div>

                  <div className="content-div">
                    <div className="mb-4">
                      <div className="button-text mb-2">
                        Person(s) to be searched
                      </div>
                      <div className="body-text-normal">
                        {' '}
                        {judgementChecker.personJudgementChecker.personInfo
                          .title +
                          ' ' +
                          judgementChecker.personJudgementChecker.personInfo
                            .firstName +
                          ' ' +
                          judgementChecker.personJudgementChecker.personInfo
                            .lastName}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="button-text mb-2">
                        Address(es) to be searched
                      </div>
                      <div className="body-text-normal">
                        {judgementChecker.personJudgementChecker.personInfo
                          .fullAddress.length > 0 ? (
                          judgementChecker.personJudgementChecker.personInfo
                            .fullAddress
                        ) : (
                          <>
                            <div>
                              {
                                judgementChecker.personJudgementChecker
                                  .personInfo.street
                              }
                            </div>
                            <div>
                              {
                                judgementChecker.personJudgementChecker
                                  .personInfo.city
                              }
                            </div>
                            <div>
                              {
                                judgementChecker.personJudgementChecker
                                  .personInfo.postalCode
                              }
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* Register(s) to be searched */}
                    <Registers
                      probe={judgementChecker.personJudgementChecker}
                    />
                  </div>

                  {!autoComplete() && (
                    <>
                      <div className="edit-remove-btn-div">
                        <div>
                          <button className="edit-btn" onClick={editReport}>
                            <img src={editIcon} className="mx-1" /> Edit
                          </button>
                        </div>

                        <div>
                          <button className="remove-btn" onClick={removeReport}>
                            <img src={removeIcon} className="mx-1" /> Remove
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <Payment />
                  {user && !user?.guest && (
                    <div style={{ width: '100%', marginTop: '20px' }}>
                      <OneFinalCheck
                        setPrivacyPolicy={setPrivacyPolicy}
                        setAvaibleViaTrust={setAvaibleViaTrust}
                        setLinkedToSearch={setLinkedToSearch}
                        avaibleViaTrust={avaibleViaTrust}
                        linkedToSearch={linkedToSearch}
                        privacyPolicy={privacyPolicy}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <ProbeButton
                  name={user && !user?.guest ? 'Checkout' : 'Continue'}
                  backgroundColor={'#0466D1'}
                  borderRadius={'10px'}
                  borderColor={'#blue'}
                  height={'60px'}
                  width={'237px'}
                  onClick={checkoutButtonClicked}
                  fontColor="#FFF"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default CheckReviewPerson;
