import React from 'react';
import './CEIinsightResultsCard.css';
import { nationalityToFlag, statusDiv, GradientIcon } from '../../../utils/helper';
import { getMonthName } from '../../../utils/common';
import PersonIcon from '@mui/icons-material/Person';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const InsightCard = ({ officer }) => {
  const isNeedActive = !officer.resigned_on && officer.need >= 20;

  const formatCompanies = officers => {
    if (officers.length === 0) return null;

    if (officers.length === 1) {
      return officers[0]?.company_name; // Only one officer
    } else {
      return (
        <>
          {officers[0]?.company_name}{' '}
          {/* Display only the first officer's name */}
          &nbsp;
          <a href="#">
            & {officers.length - 1} others{' '}
            {/* Display how many others there are */}
          </a>
        </>
      );
    }
  };

  return (
    <div className="corporate-insights-result-card">
      <div className="corporate-insights-result-card-content">
        <div className="corporate-insights-result-card-content-header">
          <div className="corporate-insights-result-card-header-container">
            <div className="corporate-insights-result-card-header-left">
              <GradientIcon IconComponent={PersonIcon} />
              <span className="corporate-insights-result-card-header-name">{officer.name}</span>
              {officer.nationality && (
                <div className="corporate-insights-result-card-header-flag">
                  {nationalityToFlag[
                    officer?.nationality?.toUpperCase().replace(' ', '')
                  ] && (
                      <img
                        src={
                          nationalityToFlag[
                          officer?.nationality?.toUpperCase().replace(' ', '')
                          ]
                        }
                        alt={`${officer.nationality} flag`}
                        className="corporate-insights-result-card-flag-icon"
                      />
                    )}
                </div>
              )}
            </div>
            <div className="corporate-insights-result-card-header-right">
              <GradientIcon IconComponent={LinkedInIcon} />
              <GradientIcon IconComponent={LanguageIcon} />
            </div>
          </div>
        </div>
        <div className="corporate-insights-result-card-body">
          {officer.occupation && (
            <div className="corporate-insights-result-card-info-row">
              <span className="corporate-insights-result-card-value">
                {officer.occupation}
              </span>
            </div>
          )}
          {officer?.officer_role && (
            <div className="corporate-insights-result-card-info-row">
              <span className="corporate-insights-result-card-value">
                {officer?.officer_role}
              </span>
            </div>
          )}
          {officer?.date_of_birth?.month && officer?.date_of_birth?.year && (
            <div className="corporate-insights-result-card-info-row">
              <span className="corporate-insights-result-card-value">
                Born in&nbsp;
                {getMonthName(officer?.date_of_birth?.month)}&nbsp;
                {officer.date_of_birth.year}
              </span>
            </div>
          )}
          <div className="corporate-insights-result-card-info-row">
            <span
              className={`corporate-insights-result-card-value ${isNeedActive
                ? 'corporate-insights-result-card-status-active'
                : 'corporate-insights-result-card-status-inactive'
                }`}
            >
              {officer?.resigned_on ? statusDiv('resigned') : statusDiv('active')}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default InsightCard;
