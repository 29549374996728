import React from 'react';
import './PostAuthFlow.css';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import { useNavigate } from 'react-router';

const PostAuthFlow = ({ subMessage, headerMessage }) => {
  const navigate = useNavigate();

  const handleJudgmentCheckerClick = () => {
    navigate('/ccj-dashboard/judgement-checker');
    window.location.reload();
  };
  const handleCorporateExplorer = () => {
    navigate('ce-dashboard/ce-corporate-explorer');
    window.location.reload();
  };
  const handleCorporateLinks = () => {
    navigate('/cl-dashboard/search-entites');
    window.location.reload();
  };
  const handleCorporateInsights = () => {
    navigate('/cei-dashboard/cei-corporate-explore-insight');
    window.location.reload();
  };

  return (
    <div className="post-auth-flow-container-main">
      <div className="post-auth-flow-container-main-image">
        <h2 className="post-auth-flow-container-main-heading">
          {headerMessage}
        </h2>
        <h2 className="post-auth-flow-container-main-subheading">
          {subMessage}
        </h2>
      </div>
      <div className="post-auth-flow-container-card-container ">
        {/* Judgement Checker Card */}
        <div className="post-auth-flow-container-judgement-card">
          <img
            src="https://www.probedigital.co.uk/hubfs/Judgement_checker.png"
            alt="Judgement Checker"
            className="post-auth-flow-container-card-image"
          />
          <h3 className="post-auth-flow-container-card-title">
            Judgement Checker
          </h3>
          <p className="post-auth-flow-container-card-description">
            Your Trusted Source for Official UK Register Verification.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProbeButton
              name={'Try now'}
              onClick={handleJudgmentCheckerClick}
            />
          </div>
        </div>
        {/* Corporate Explorer Card */}
        <div className="post-auth-flow-corporate-explorer-card">
          <img
            src="https://www.probedigital.co.uk/hubfs/Explorer.svg"
            alt="Corporate Search"
            className="post-auth-flow-container-card-image"
          />
          <h3 className="post-auth-flow-container-card-title">
            Corporate Explorer
          </h3>
          <p className="post-auth-flow-container-card-description">
            Effortlessly learn about the companies that matter with
            comprehensive insight on past and present activity.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProbeButton name={'Try now'} onClick={handleCorporateExplorer} />
          </div>
        </div>
      </div>
      <div className="post-auth-flow-container-card-container ">
        {/* Judgement Checker Card */}
        <div className="post-auth-flow-container-judgement-card">
          <img
            src="https://www.probedigital.co.uk/hubfs/Corporate_links-1.svg"
            alt="Judgement Checker"
            className="post-auth-flow-container-card-image"
          />
          <h3 className="post-auth-flow-container-card-title">
            Corporate Insights
          </h3>
          <p className="post-auth-flow-container-card-description">
            Your Trusted Source for Official UK Register Verification.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProbeButton name={'Try now'} onClick={handleCorporateInsights} />
          </div>
        </div>

        {/* Corporate Explorer Card */}
        <div className="post-auth-flow-corporate-explorer-card">
          <img
            src="https://www.probedigital.co.uk/hubfs/Uk%20Foreign%20Ownership.svg"
            alt="Corporate Search"
            className="post-auth-flow-container-card-image"
          />
          <h3 className="post-auth-flow-container-card-title">
            Corporate Links
          </h3>
          <p className="post-auth-flow-container-card-description">
            Effortlessly learn about the companies that matter with
            comprehensive insight on past and present activity.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProbeButton name={'Try now'} onClick={handleCorporateLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostAuthFlow;
