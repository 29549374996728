import React from "react";
import "./CEIDisableAndSignificantControl.css"; // Import your CSS file for styling

const CEIDisableAndSignificantControl = ({
  title,
  message,
  icon,
  people,
  buttonText,
}) => {
  return (
    <div className="content-People" id="tiles_People">
      <div className="tiles-x">
        <span className="people-close-icon"></span>
      </div>
      <div className="title"> {people}</div>
      <div className="upgrade-notice">
        <div className="upgrade-box">
          <div
            className="icon"
            style={{ backgroundImage: `url(${icon})` }}
          ></div>
          {/* <div className="icon"></div> */}
          <div className="message">
            <strong>{title}</strong>
            <p>{message}</p>
            <button className="plans-button" disabled={true}>{buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEIDisableAndSignificantControl;
