import { Routes, Route } from 'react-router-dom';
import HeaderDropdown from './components/HeaderDropdownNative';
import Sidebar from './components/Sidebar';
import { routesConfig } from './routeConfig.js';
import usePageTitle from './context/PageContext/PageContext.js';
import ToolTips from './components/ToolTips/ToolTips.js';
import { AppContext } from './context/Context';
import { useContext } from 'react';

const MainLayout = () => {
  const pageTitle = usePageTitle();
  const { setToggleSidebar, toggleSidebar } = useContext(AppContext);

  const toggleSidebarHandler = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      if (route.children) {
        return (
          <Route key={index} path={route.path} element={route.element}>
            {renderRoutes(route.children)}
          </Route>
        );
      }
      return <Route key={index} path={route.path} element={route.element} />;
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="main-toolbar">
        <div>
          <div className="burger-main" onClick={toggleSidebarHandler}>
            &#9776;
          </div>
        </div>
        <HeaderDropdown />
      </div>
      <Sidebar />
      <Routes>{renderRoutes(routesConfig)}</Routes>
      <ToolTips />
    </div>
  );
};

export default MainLayout;
