import './CESearchEntities.css';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import InsightCard from '../../../components/CorporateExploreInsights/CEIInsightResultsCard/CEIinsightResultsCard';
import CESAdvancedFilter from './CESAdvancedFilter/CESAdvancedFilter';
import CESAdvancedFilterPerson from './CESAdvancedFilterPerson/CESAdvancedFilterPerson';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/Context';
import { red } from '@mui/material/colors';
import { GradientIcon } from '../../../utils/helper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CESearchEntitiesSection = ({
  searchContainerRef,
  setIsCESearchOnlyVisible,
  isCESearchOnlyVisible,
  inputValue,
  handleInputFocus,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  itemClicked,
  showCards = true,
  totalOfficersAndCompanies,
  disabled,
  startIcon,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const { handleNavigation, setHandleNavigation } = useContext(AppContext);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(true);
  const navigate = useNavigate();

  const toggleAdvancedFilter = e => {
    e.preventDefault();
    setShowAdvancedFilter(prev => !prev);
  };

  const totalOfficerAndComapnies = (company) => {
    setHandleNavigation(previousState => ({
      ...previousState,
      searchInsight: inputValue,
    }));
    navigate('/cei-dashboard/cei-corporate-explore-insight');
  };

  // Logic to calculate the items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = suggestions ? suggestions.slice(indexOfFirstItem, indexOfLastItem) : [];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageCount = Math.ceil((suggestions ? suggestions.length : 0) / itemsPerPage);

  const handleItemClick = (type, item) => {
    itemClicked(type, item);
    setIsCESearchOnlyVisible(true);
    handleChange({ target: { value: '' } });
  };

  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          ref={searchContainerRef}
          style={{
            width: '502px'
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: isCESearchOnlyVisible ? '' : '100%',
            }}
          >
            {startIcon && (
              <div
                className="input-icon"
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                  color: selectedType ? '#000' : '#aaa',
                }}
              >
                {startIcon}
              </div>
            )}
            <input
              type="text"
              className="cesearch-entites-search-input"
              value={inputValue}
              placeholder={selectedType || 'Search'}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={handleChange}
              disabled={disabled}
              style={{
                width: isCESearchOnlyVisible ? '502px' : '100%',
                paddingLeft: startIcon ? '35px' : '10px',
                border: isCESearchOnlyVisible ? '' : '2px #029CBD solid',
              }}
            />
            <div
              onClick={toggleAdvancedFilter}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >

              {inputValue.length > 0 && (
                <GradientIcon IconComponent={FilterAltIcon} />
              )}
            </div>
          </div>
          <div>
            {isLoadingPage && (
              <div>
                <img src={loadingGif} alt="Loading..." width={30} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`cesearch-entites-advanced-filter-container ${!showAdvancedFilter ? 'show' : ''}`}
        >
          {!showAdvancedFilter && (
            <div className="cesearch-entites-advanced-filter">
              <div className="container" style={{ position: 'relative' }}>
                <h2 className="advance-search-heading">Advance people Search</h2>
                <button className="ce-search-entities-close-button" onClick={() => setShowAdvancedFilter(true)}>
                  X
                </button>
              </div>
              <hr />
              <div className="container">
                {selectedType === 'People' ? (
                  <CESAdvancedFilterPerson />
                ) : (
                  <CESAdvancedFilter />
                )}
              </div>
            </div>
          )}
        </div>



        {!isLoadingPage && !isCESearchOnlyVisible && (
          <div className="container">
            <div className="row">
              {selectedType === 'People'
                ? currentItems.map(person => (
                  <div className="col-lg-4 col-md-4" key={person.id}>
                    <div onClick={() => handleItemClick(selectedType, person)}>
                      <InsightCard officer={person} />
                    </div>
                  </div>
                ))
                : currentItems.map(comp => (
                  <div className="col-lg-4 col-md-4" key={comp.id}>
                    <div onClick={() => handleItemClick(selectedType, comp)}>
                      <CompanyItem company={comp} isCardView={true} />
                    </div>
                  </div>
                ))}
            </div>
            {inputValue.length > 0 && (
              <div className="ce-search-entities-pagination-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="ce-search-entities-pagination-button prev-next" // Added `prev-next` class here
                >
                  &lt; PREVIOUS
                </button>
                {[...Array(pageCount)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`ce-search-entities-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === pageCount}
                  className="ce-search-entities-pagination-button prev-next" // Added `prev-next` class here
                >
                  NEXT &gt;
                </button>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default CESearchEntitiesSection;
