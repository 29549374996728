import React, { useState, useContext, useEffect } from 'react';
import './CESAdvancedFilterPerson.css';
import DateRangeSlider from '../CESAdvancedFilter/CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilterPerson = () => {
  const minDate = 1800;
  const maxDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);

  // Temporary state for filters
  const [tempFilterPerson, setTempFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    dateRange: [minDate, maxDate],
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);


  const [advanceFilterPerson, setAdvanceFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    dateRange: [minDate, maxDate],
  });

  // Update temp filters
  const handleTempChange = e => {
    setTempFilterPerson({
      ...tempFilterPerson,
      [e.target.name]: e.target.value,
    });
  };

  const handleTempDateRangeChange = newRange => {
    setTempFilterPerson({
      ...tempFilterPerson,
      dateRange: newRange,
    });
  };

  const applyFilters = () => {
    setAdvanceFilterPerson(tempFilterPerson);
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: tempFilterPerson,
    });
  };

  useEffect(() => {
    const isAnyFieldFilled =
      tempFilterPerson.nationality ||
      tempFilterPerson.occupation ||
      tempFilterPerson.positionRole ||
      (tempFilterPerson.dateRange[0] !== minDate || tempFilterPerson.dateRange[1] !== maxDate);
    setIsSearchEnabled(isAnyFieldFilled);
  }, [tempFilterPerson]);

  return (
    <div className="row">
      <div className="col-md-4">
        <label htmlFor="name" className="form-label filter-label">
          Person Name
        </label>
        <input
          className="form-control company-person-advance-filter-form-control"
          id="name"
          placeholder="Fullname"
          name="name"
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="occupation" className="form-label filter-label">
          Occupation
        </label>
        <select
          className="form-control company-person-advance-filter-form-control"
          id="occupation"
          name="occupation"
          value={tempFilterPerson.occupation}
          onChange={handleTempChange}
        >
          <option value="" selected disabled>
            Occupation
          </option>
          <option>BUSINESS PERSON</option>
          <option>MATHEMATICIAN</option>
          <option>OPERATIONS MANAGER</option>
          <option>COMPANY DIRECTOR</option>
          <option>DIRECTOR</option>
          <option>CONSULTANT</option>
          <option>Nurse</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="positionRole" className="form-label filter-label">
          Officer Role
        </label>
        <select
          className="form-control company-person-advance-filter-form-control"
          id="positionRole"
          name="positionRole"
          value={tempFilterPerson.positionRole}
          onChange={handleTempChange}
        >
          <option value="" selected disabled>
            Officer Role
          </option>
          <option>Current Director</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="address" className="form-label filter-label">
          Address
        </label>
        <input
          className="form-control company-person-advance-filter-form-control"
          id="address"
          placeholder="Address"
          name="address"
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="nationality" className="form-label filter-label">
          Nationality:
        </label>
        <select
          className="form-control company-person-advance-filter-form-control"
          id="nationality"
          name="nationality"
          value={tempFilterPerson.nationality}
          onChange={handleTempChange}
        >
          <option value="" selected disabled>
            Nationality
          </option>
          <option>England</option>
          <option>British</option>
          <option>AFGHAN</option>
          <option>ROMANIAN</option>
          <option>ENGLISH</option>
          <option>Pakistani</option>
          <option>UKRAINIAN</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="postalCode" className="form-label filter-label">
          Postal Code
        </label>
        <input
          className="form-control company-person-advance-filter-form-control"
          id="postalCode"
          placeholder="Post Code"
          name="postalCode"
        />
      </div>
      <div className="col-md-4">
        <DateRangeSlider
          labelName={'Age'}
          values={tempFilterPerson.dateRange}
          setValues={handleTempDateRangeChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="col-md-12 mt-3">
        <div className="person-filter-search">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={isSearchEnabled ? 'linear-gradient(to right, #0466D1, #00D1A9)' : '#8A8A8A'}
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={isSearchEnabled ? false : true}
            onClick={applyFilters}
          />
        </div>
      </div>
    </div >
  );
};

export default CESAdvancedFilterPerson;
