import React, { useContext, useEffect, useState } from 'react';
import './CIEDocument.css';
import CIEDocRow from './CIEDocRow';
import { corporateExplorerGetFinancialDocuments } from '../../../api/corporateExplorer/corporateExplorer';
import { AppContext } from '../../../context/Context';
import TableLoadingScreen from '../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';


const CIEDocument = ({ selectedCompany }) => {
  const [financialDocument, setFinancialDocument] = useState([]);
  const { setFinancialAccountsDoc } = useContext(AppContext);
  const [isDocumentUrlGenerating, setIsDocumentUrlGenerating] = useState(false);
  const handleTileRemove = id => { };
  const [showLoadingForDocument, setShowLoadingForDocument] =
    useState(false);

  useEffect(() => {
    if (selectedCompany) {
      const fetchDocument = async () => {
        try {
          const documentRes = await corporateExplorerGetFinancialDocuments(
            selectedCompany?.company_number
          );
          setFinancialDocument(documentRes);
          setFinancialAccountsDoc(documentRes);
        } catch (error) {
          setFinancialDocument([]);
        }
      };
      fetchDocument();
    }
  }, [selectedCompany]);

  return (
    <div>
      {Array.isArray(financialDocument) && (
        <div
          className="coporate-Insights-Document-content "
          id="tiles_Documents"
          draggable="false"
        >
          <div
            className="tiles-x"
            onClick={() => handleTileRemove('tiles_Documents')}
          >
            <span className="c-close-icon"></span>
          </div>
          <div className="coporate-Insights-Document">
            Documents
            <span className="coporateInsights-Document-count">
              {financialDocument?.length}
            </span>
          </div>
          <div className="striped-table coporate-Insights-Document-container">
            <table>
              <tbody>
                {financialDocument?.map(doc => (
                  <CIEDocRow
                    doc={doc}
                    setIsDocumentUrlGenerating={setIsDocumentUrlGenerating}
                    isDocumentUrlGenerating={isDocumentUrlGenerating}
                    setShowLoadingForDocument={setShowLoadingForDocument}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <a
              href="?page=documents"
              className="coporate-Insights-Document-link-button"
              draggable="false"
            >
              VIEW DOCUMENTS<span className="chevron-right-icon"></span>
            </a>
          </div>
          {showLoadingForDocument && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <TableLoadingScreen width="80px" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CIEDocument;
