import React from 'react';
import './CompanyItem.css';
import { nationalityToFlag, statusDiv, GradientIcon } from '../../../../utils/helper';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import ApartmentIcon from '@mui/icons-material/Apartment';

const CompanyItem = ({ company }) => {
  const isNeedActive = !company.company_status && company.need >= 20;

  const formatOfficers = (officers) => {
    if (officers?.length === 0) return null;
    if (officers?.length === 1) {
      return officers[0]?.name;
    } else {
      return (
        <>
          {officers[0]?.name}
          &nbsp;
          <a href="#">& {officers?.length - 1} others </a>
        </>
      );
    }
  };

  return (
    <div className="corporate-insights-result-card">
      <div className="corporate-insights-result-card-content">
        <div className="corporate-insights-result-card-content-header">
          <div className="corporate-insights-result-card-header-container">
            <div className="corporate-insights-result-card-header-left">
              <GradientIcon IconComponent={ApartmentIcon} />
              <span className="header-name">{company?.title}</span>
            </div>
            <div className="corporate-insights-result-card-header-right">
              <GradientIcon IconComponent={LinkedInIcon} />
              <GradientIcon IconComponent={LanguageIcon} />
            </div>
          </div>
        </div>
        <div className="corporate-insights-result-card-body">
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-value">
              {company?.address?.length > 50
                ? `${company.address.slice(0, 50)}...`
                : company?.address}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-value">
              {company?.company_number}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span
              className={`corporate-insights-result-card-value ${isNeedActive
                ? 'corporate-insights-result-card-status-active'
                : 'corporate-insights-result-card-status-inactive'
                }`}
            >
              {statusDiv(company?.company_status)}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
