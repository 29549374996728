import React, { useState, useEffect, useContext } from 'react';
import './CLGraphTotalDropdown.css';
import {
  getNodeNameById,
  shortenStringByCharCount,
} from '../../../utils/helper';
import { AppContext } from '../../../context/Context';

const CLGraphTotalDropdown = ({ graphList, setSelectedGraph }) => {
  const [selectedGraphIndex, setSelectedGraphIndex] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { clGraph, setClGraph, user } = useContext(AppContext);

  const handleSelectClick = () => {
    setIsDropdownOpen(prev => !prev);
    setClGraph(previousState => ({
      ...previousState,
      isOpenRightSidebar: false,
    }));
  };

  const handleSelectItem = index => {
    setSelectedGraphIndex(index);
    setSelectedGraph(graphList[index]);
    setClGraph(previousState => ({
      ...previousState,
      selectedGraph: graphList[index],
    }));

    setIsDropdownOpen(false);
  };

  // Set default selected graph if one is available
  useEffect(() => {
    if (graphList.length > 0) {
      setSelectedGraphIndex(0);
      setSelectedGraph(graphList[0]);
      setClGraph(previousState => ({
        ...previousState,
        selectedGraph: graphList[0],
      }));
    }
  }, [graphList, setSelectedGraph]);

  return (
    <div
      className="cl-graph-total-dropdown-container"
      onBlur={() => setIsDropdownOpen(false)}
      tabIndex={0}
    >
      <div
        className="cl-graph-total-dropdown-header"
        onClick={handleSelectClick}
      >
        <div className="cl-graph-total-dropdown-header-bottom">
          Graph {selectedGraphIndex !== null ? selectedGraphIndex + 1 : ''} of{' '}
          {graphList && graphList?.length}
        </div>
        <div className="cl-graph-total-dropdown-header-bottom-sub-text">
          {selectedGraphIndex !== null
            ? getNodeNameById(
                graphList[selectedGraphIndex]?.nodes,
                graphList[selectedGraphIndex]?.meta?.selectedEntity?.ids,
                true
              )
            : 'Select a Graph'}
        </div>
      </div>
      {isDropdownOpen && (
        <div className="cl-graph-total-dropdown-list">
          {graphList.map((graph, index) => (
            <div
              key={index}
              className="cl-graph-total-dropdown-item"
              onClick={() => handleSelectItem(index)}
            >
              {getNodeNameById(graph?.nodes, graph?.meta?.selectedEntity?.ids)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CLGraphTotalDropdown;
