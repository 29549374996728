import LocalStorageService from './LocalStorageServices';
import { months } from './common';
import CONSTANTS from './constants';
import BritishFlag from '../assets/flags/GB.png';
import Andorra from '../assets/flags/AD.svg';
import AscensionIsland from '../assets/flags/AI.svg';
import Uae from '../assets/flags/AE.svg';
import Afghanistan from '../assets/flags/AF.svg';
import AlandIslands from '../assets/flags/AX.svg';
import SaintBarthelemy from '../assets/flags/BL.svg';
import Bonaire from '../assets/flags/BQ.svg';
import Curacao from '../assets/flags/CW.svg';
import Congo from '../assets/flags/CG.svg';
import IvoryCoast from '../assets/flags/CI.svg';
import CzechRepublic from '../assets/flags/CZ.svg';
import SouthGeorgiaAndSouthSandwichIslands from '../assets/flags/GS.svg';
import HeardAndMcDonaldIslands from '../assets/flags/HM.svg';
import IsleOfMan from '../assets/flags/IM.svg';
import AntiguaAndBarbuda from '../assets/flags/AG.svg';
import Anguilla from '../assets/flags/AI.svg';
import Albania from '../assets/flags/AL.svg';
import Armenia from '../assets/flags/AM.svg';
import Angola from '../assets/flags/AO.svg';
import Antarctica from '../assets/flags/AQ.svg';
import Argentina from '../assets/flags/AR.svg';
import AmericanSamoa from '../assets/flags/AS.svg';
import Austria from '../assets/flags/AT.svg';
import Australia from '../assets/flags/AU.svg';
import Aruba from '../assets/flags/AW.svg';
import Azerbaijan from '../assets/flags/AZ.svg';
import BosniaAndHerzegovina from '../assets/flags/BA.svg';
import Barbados from '../assets/flags/BB.svg';
import Bangladesh from '../assets/flags/BD.svg';
import Belgium from '../assets/flags/BE.svg';
import BurkinaFaso from '../assets/flags/BF.svg';
import Bulgaria from '../assets/flags/BG.svg';
import Bahrain from '../assets/flags/BH.svg';
import Burundi from '../assets/flags/BI.svg';
import Benin from '../assets/flags/BJ.svg';
import Bermuda from '../assets/flags/BM.svg';
import Brunei from '../assets/flags/BN.svg';
import Bolivia from '../assets/flags/BO.svg';
import Brazil from '../assets/flags/BR.svg';
import Bahamas from '../assets/flags/BS.svg';
import Bhutan from '../assets/flags/BT.svg';
import BouvetIsland from '../assets/flags/BV.svg';
import Botswana from '../assets/flags/BW.svg';
import Belarus from '../assets/flags/BY.svg';
import Belize from '../assets/flags/BZ.svg';
import Canada from '../assets/flags/CA.svg';
import CocosIslands from '../assets/flags/CC.svg';
import CentralAfricanRepublic from '../assets/flags/CF.svg';
import Switzerland from '../assets/flags/CH.svg';
import CookIslands from '../assets/flags/CK.svg';
import Chile from '../assets/flags/CL.svg';
import Cameroon from '../assets/flags/CM.svg';
import China from '../assets/flags/CN.svg';
import Colombia from '../assets/flags/CO.svg';
import CostaRica from '../assets/flags/CR.svg';
import Cuba from '../assets/flags/CU.svg';
import CapeVerde from '../assets/flags/CV.svg';
import ChristmasIsland from '../assets/flags/CX.svg';
import Cyprus from '../assets/flags/CY.svg';
import Germany from '../assets/flags/DE.svg';
import Djibouti from '../assets/flags/DJ.svg';
import Denmark from '../assets/flags/DK.svg';
import Dominica from '../assets/flags/DM.svg';
import DominicanRepublic from '../assets/flags/DO.svg';
import Algeria from '../assets/flags/DZ.svg';
import Ecuador from '../assets/flags/EC.svg';
import Estonia from '../assets/flags/EE.svg';
import Egypt from '../assets/flags/EG.svg';
import WesternSahara from '../assets/flags/EH.svg';
import Eritrea from '../assets/flags/ER.svg';
import Spain from '../assets/flags/ES.svg';
import Ethiopia from '../assets/flags/ET.svg';
import EuropeanUnion from '../assets/flags/EU.svg';
import Finland from '../assets/flags/FI.svg';
import Fiji from '../assets/flags/FJ.svg';
import FalklandIslands from '../assets/flags/FK.svg';
import Micronesia from '../assets/flags/FM.svg';
import FaroeIslands from '../assets/flags/FO.svg';
import France from '../assets/flags/FR.svg';
import Gabon from '../assets/flags/GA.svg';
import UnitedKingdom from '../assets/flags/GB.svg';
import Grenada from '../assets/flags/GD.svg';
import Abkhazia from '../assets/flags/GE-AB.svg';
import SouthOssetia from '../assets/flags/GE-OS.svg';
import Georgia from '../assets/flags/GE.svg';
import FrenchGuiana from '../assets/flags/GF.svg';
import Guernsey from '../assets/flags/GG.svg';
import Ghana from '../assets/flags/GH.svg';
import Gibraltar from '../assets/flags/GI.svg';
import Greenland from '../assets/flags/GL.svg';
import Gambia from '../assets/flags/GM.svg';
import Guinea from '../assets/flags/GN.svg';
import Guadeloupe from '../assets/flags/GP.svg';
import EquatorialGuinea from '../assets/flags/GQ.svg';
import Greece from '../assets/flags/GR.svg';
import Guatemala from '../assets/flags/GT.svg';
import Guam from '../assets/flags/GU.svg';
import GuineaBissau from '../assets/flags/GW.svg';
import Guyana from '../assets/flags/GY.svg';
import HongKong from '../assets/flags/HK.svg';
import Honduras from '../assets/flags/HN.svg';
import Croatia from '../assets/flags/HR.svg';
import Haiti from '../assets/flags/HT.svg';
import Hungary from '../assets/flags/HU.svg';
import CanaryIslands from '../assets/flags/IC.svg';
import Indonesia from '../assets/flags/ID.svg';
import Ireland from '../assets/flags/IE.svg';
import Israel from '../assets/flags/IL.svg';
import India from '../assets/flags/IN.svg';
import BritishIndianOceanTerritory from '../assets/flags/IO.svg';
import Iraq from '../assets/flags/IQ.svg';
import Iran from '../assets/flags/IR.svg';
import Iceland from '../assets/flags/IS.svg';
import Italy from '../assets/flags/IT.svg';
import Jersey from '../assets/flags/JE.svg';
import Jamaica from '../assets/flags/JM.svg';
import Jordan from '../assets/flags/JO.svg';
import Japan from '../assets/flags/JP.svg';
import Kenya from '../assets/flags/KE.svg';
import Kyrgyzstan from '../assets/flags/KG.svg';
import Cambodia from '../assets/flags/KH.svg';
import Kiribati from '../assets/flags/KI.svg';
import Comoros from '../assets/flags/KM.svg';
import NorthKorea from '../assets/flags/KP.svg';
import SouthKorea from '../assets/flags/KR.svg';
import Kuwait from '../assets/flags/KW.svg';
import CaymanIslands from '../assets/flags/KY.svg';
import Kazakhstan from '../assets/flags/KZ.svg';
import Laos from '../assets/flags/LA.svg';
import Lebanon from '../assets/flags/LB.svg';
import SaintLucia from '../assets/flags/LC.svg';
import Liechtenstein from '../assets/flags/LI.svg';
import SriLanka from '../assets/flags/LK.svg';
import Liberia from '../assets/flags/LR.svg';
import Lesotho from '../assets/flags/LS.svg';
import Lithuania from '../assets/flags/LT.svg';
import Luxembourg from '../assets/flags/LU.svg';
import Latvia from '../assets/flags/LV.svg';
import Libya from '../assets/flags/LY.svg';
import Morocco from '../assets/flags/MA.svg';
import Monaco from '../assets/flags/MC.svg';
import Moldova from '../assets/flags/MD.svg';
import Montenegro from '../assets/flags/ME.svg';
import SaintMartin from '../assets/flags/MF.svg';
import Madagascar from '../assets/flags/MG.svg';
import MarshallIslands from '../assets/flags/MH.svg';
import NorthMacedonia from '../assets/flags/MK.svg';
import Mali from '../assets/flags/ML.svg';
import Myanmar from '../assets/flags/MM.svg';
import Mongolia from '../assets/flags/MN.svg';
import Macau from '../assets/flags/MO.svg';
import NorthernMarianaIslands from '../assets/flags/MP.svg';
import Martinique from '../assets/flags/MQ.svg';
import Mauritania from '../assets/flags/MR.svg';
import Montserrat from '../assets/flags/MS.svg';
import Malta from '../assets/flags/MT.svg';
import Mauritius from '../assets/flags/MU.svg';
import Maldives from '../assets/flags/MV.svg';
import Malawi from '../assets/flags/MW.svg';
import Mexico from '../assets/flags/MX.svg';
import Malaysia from '../assets/flags/MY.svg';
import Mozambique from '../assets/flags/MZ.svg';
import Namibia from '../assets/flags/NA.svg';
import NewCaledonia from '../assets/flags/NC.svg';
import Niger from '../assets/flags/NE.svg';
import NorfolkIsland from '../assets/flags/NF.svg';
import Nigeria from '../assets/flags/NG.svg';
import Nicaragua from '../assets/flags/NI.svg';
import Netherlands from '../assets/flags/NL.svg';
import Norway from '../assets/flags/NO.svg';
import Nepal from '../assets/flags/NP.svg';
import Nauru from '../assets/flags/NR.svg';
import Niue from '../assets/flags/NU.svg';
import NewZealand from '../assets/flags/NZ.svg';
import Oman from '../assets/flags/OM.svg';
import Panama from '../assets/flags/PA.svg';
import Peru from '../assets/flags/PE.svg';
import FrenchPolynesia from '../assets/flags/PF.svg';
import PapuaNewGuinea from '../assets/flags/PG.svg';
import Philippines from '../assets/flags/PH.svg';
import Pakistan from '../assets/flags/PK.svg';
import Poland from '../assets/flags/PL.svg';
import PitcairnIslands from '../assets/flags/PN.svg';
import PuertoRico from '../assets/flags/PR.svg';
import Palestine from '../assets/flags/PS.svg';
import Portugal from '../assets/flags/PT.svg';
import Palau from '../assets/flags/PW.svg';
import Paraguay from '../assets/flags/PY.svg';
import Qatar from '../assets/flags/QA.svg';
import Reunion from '../assets/flags/RE.svg';
import Romania from '../assets/flags/RO.svg';
import Serbia from '../assets/flags/RS.svg';
import Russia from '../assets/flags/RU.svg';
import Rwanda from '../assets/flags/RW.svg';
import SaudiArabia from '../assets/flags/SA.svg';
import SolomonIslands from '../assets/flags/SB.svg';
import Seychelles from '../assets/flags/SC.svg';
import Sudan from '../assets/flags/SD.svg';
import Sweden from '../assets/flags/SE.svg';
import Singapore from '../assets/flags/SG.svg';
import SaintHelena from '../assets/flags/SH.svg';
import Slovenia from '../assets/flags/SI.svg';
import Slovakia from '../assets/flags/SK.svg';
import SierraLeone from '../assets/flags/SL.svg';
import SanMarino from '../assets/flags/SM.svg';
import Senegal from '../assets/flags/SN.svg';
import Somalia from '../assets/flags/SO.svg';
import Suriname from '../assets/flags/SR.svg';
import SouthSudan from '../assets/flags/SS.svg';
import ElSalvador from '../assets/flags/SV.svg';
import SintMaarten from '../assets/flags/SX.svg';
import Syria from '../assets/flags/SY.svg';
import Eswatini from '../assets/flags/SZ.svg';
import Chad from '../assets/flags/TD.svg';
import Togo from '../assets/flags/TG.svg';
import Thailand from '../assets/flags/TH.svg';
import Tajikistan from '../assets/flags/TJ.svg';
import Tokelau from '../assets/flags/TK.svg';
import TimorLeste from '../assets/flags/TL.svg';
import Turkmenistan from '../assets/flags/TM.svg';
import Tunisia from '../assets/flags/TN.svg';
import Tonga from '../assets/flags/TO.svg';
import Turkey from '../assets/flags/TR.svg';
import Tuvalu from '../assets/flags/TV.svg';
import Taiwan from '../assets/flags/TW.svg';
import Tanzania from '../assets/flags/TZ.svg';
import Ukraine from '../assets/flags/UA.svg';
import Uganda from '../assets/flags/UG.svg';
import UnitedStatesMinorOutlyingIslands from '../assets/flags/UM.svg';
import UnitedStates from '../assets/flags/US.svg';
import Uruguay from '../assets/flags/UY.svg';
import Uzbekistan from '../assets/flags/UZ.svg';
import VaticanCity from '../assets/flags/VA.svg';
import Venezuela from '../assets/flags/VE.svg';
import BritishVirginIslands from '../assets/flags/VG.svg';
import Vietnam from '../assets/flags/VN.svg';
import Vanuatu from '../assets/flags/VU.svg';
import Samoa from '../assets/flags/WS.svg';
import Kosovo from '../assets/flags/XK.svg';
import Yemen from '../assets/flags/YE.svg';
import Mayotte from '../assets/flags/YT.svg';
import SouthAfrica from '../assets/flags/ZA.svg';
import Zambia from '../assets/flags/ZM.svg';
import Zimbabwe from '../assets/flags/ZW.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTrendUp,
  faArrowTrendDown,
} from '@fortawesome/free-solid-svg-icons';

export const getCardsByIndex = (staticCardData, indexes) => {
  return indexes
    .map(index => staticCardData[index])
    .filter(card => card !== undefined);
};

export const reArrangetransformDateRange = dateRange => {
  const transformDate = date => {
    const [day, month, year] = date.split('-');
    return `${year}-${month}-${day}`;
  };
  const [startDate, endDate] = dateRange.split(' - ');
  return `${transformDate(startDate)} - ${transformDate(endDate)}`;
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getCurrentMonth = () => {
  const date = new Date();
  return months[date.getMonth()].toLowerCase();
};

export const getCurrentDateFormatted = () => {
  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month.toUpperCase()} ${year}`;
};

export const shuffleString = str => {
  const arr = str.split('');
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr.join('');
};

export const generateUniqueReferenceNumber = () => {
  const now = new Date();

  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

  const randomPart = String(Math.floor(Math.random() * 1000)).padStart(3, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}${randomPart}`;
};

export const formatDateFromString = inputString => {
  const year = inputString.substring(0, 4);
  const month = inputString.substring(4, 6);
  const day = inputString.substring(6, 8);

  return `${year}/${month}/${day}`;
};

export const determineCaseColor = jtd => {
  if (jtd) {
    if (jtd?.registrationCount === 0) {
      return '#34A91C';
    } else if (jtd?.satisfactionCount === jtd?.registrationCount) {
      return '#FDBE28';
    } else {
      return '#D1232A';
    }
  }
};

export const determineJudgmentCheckerType = judgementChecker => {
  return Object.keys(judgementChecker?.personJudgementChecker || {}).length !==
    0
    ? judgementChecker?.personJudgementChecker
    : judgementChecker?.businessJudgementChecker;
};

export const dynamicStyle = ({
  color,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  marginLeft,
  marginTop,
  marginBottom,
  display,
  justifyContent,
  textAlign,
}) => {
  return {
    color: color,
    fontFamily: fontFamily || 'Poppins',
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    marginLeft: marginLeft,
    marginTop: marginTop,
    marginBottom: marginBottom,
    display: display,
    justifyContent: justifyContent,
    textAlign: textAlign,
  };
};

export const constructSearchData = judgementChecker => {
  // Determine if the input is for a person or a business based on the presence of certain properties
  if (judgementChecker.type === 'PersonSearch') {
    return {
      type: judgementChecker.type,
      names: judgementChecker.names, // Assuming names is an array of name objects
      addresses: judgementChecker.addresses, // Assuming addresses is an array of address objects
    };
  } else if (judgementChecker.type === 'BusinessSearch') {
    return {
      type: judgementChecker.type,
      names: [
        {
          businessName: judgementChecker.companyName,
        },
      ],
    };
  } else {
    return null;
  }
};

export const getBaseUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const convertBlobUrlToBase64 = async blobUrl => {
  try {
    // Fetch the blob from the blob URL
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    // Use FileReader to convert the blob to base64
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve({ success: true, data: base64data }); // Contains the base64 string
      };
      reader.onerror = () => {
        resolve({
          success: false,
          message: 'Error reading the Blob data',
          status: 'error',
        });
      };
    });
  } catch (error) {
    console.error('Error fetching blob from URL:', error);
    return { success: false, message: error.message, status: 'error' };
  }
};

export const scrollToElement = elementId => {
  setTimeout(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0); // The delay can be adjusted if needed
};

export const getGuestUserInfo = () => {
  return {
    guest: true,
    FirebaseUser: {
      user_id: process.env.REACT_APP_DUMMY_USER_ID,
      api_key: process.env.REACT_APP_DUMMY_API_KEY,
    },
    Account: { Uid: process.env.REACT_APP_DUMMY_ACCOUNT_ID },
  };
};

// going to pay.
export const continueToCheckOut = async (
  setIsLoading,
  createCheckoutSession,
  judgementChecker,
  user
) => {
  try {
    setIsLoading(true);
    const successUrl = `${getBaseUrl()}/ccj-dashboard/report`;
    const cancelUrl = `${getBaseUrl()}/payment-cancel`;

    const response = await createCheckoutSession(
      successUrl,
      cancelUrl,
      judgementChecker,
      user?.FirebaseUser?.user_id
    );

    if (response.success) {
      setIsLoading(false);
      LocalStorageService.setItem('payment_session_id', response.session?.id);
      window.location.href = response.url;
    } else {
      setIsLoading(false);
    }
  } catch (error) {
    setIsLoading(false);
  }
};

export const flattenStructure = node => {
  let result = [];

  function recurse(currentNode) {
    if (currentNode.parent) {
      result.push(currentNode.parent);
    }
    if (currentNode.child && currentNode.child.length > 0) {
      currentNode.child.forEach(childNode => recurse(childNode));
    }
  }

  node.forEach(rootNode => recurse(rootNode));
  return result;
};

// const getDescriptionBySicCode = sicCode => {
//   const reverseLookup = Object.keys(CONSTANTS.SIC_CODE_DESCRIPTION).reduce(
//     (acc, key) => {
//       acc[CONSTANTS.SIC_CODE_DESCRIPTION[key]] = key;
//       return acc;
//     },
//     {}
//   );

//   return reverseLookup[sicCode];
// };

const getDescriptionsByPrefix = prefix => {
  try {
    return Object.keys(CONSTANTS.SIC_CODE_DESCRIPTION).filter(key =>
      CONSTANTS.SIC_CODE_DESCRIPTION[key].startsWith(prefix)
    );
  } catch (error) {
    return [];
  }
};

export const searchList = (keyword, list) => {
  try {
    if (isNumericString(keyword)) {
      const matches = getDescriptionsByPrefix(keyword);
      // Remove duplicates by converting to a Set and back to an array
      return [...new Set(matches)];
    }

    if (!isNumericString(keyword)) {
      const matches = list.filter(item =>
        item.toLowerCase().includes(keyword?.toLowerCase())
      );

      // Remove duplicates by converting to a Set and back to an array
      return [...new Set(matches)];
    }
  } catch (error) {
    return [];
  }
};

export const formatDateRange = (startDateString, endDateString) => {
  const startDate = new Date(`${startDateString.split('-')}`);
  const endDate = new Date(`${endDateString.split('-')}`);
  const startDay = startDate.getDate();
  const startMonthIndex = startDate.getMonth();
  const startYear = startDate.getFullYear();

  const endDay = endDate.getDate();
  const endMonthIndex = endDate.getMonth();
  const endYear = endDate.getFullYear();

  const formattedStartDate = `${startDay} ${months[startMonthIndex]} ${startYear}`;
  const formattedEndDate = `${endDay} ${months[endMonthIndex]} ${endYear}`;

  return `${formattedStartDate} – ${formattedEndDate}`;
};

export function getDateRange(range) {
  const today = new Date();
  switch (range) {
    case 'today':
      return formatDate(today); // Return today's date only

    case 'yesterday':
      const yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract one day in milliseconds
      return formatDate(yesterday);

    case 'tomorrow':
      const tomorrow = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Subtract one day in milliseconds
      return formatDate(tomorrow);

    case 'last 7 days': {
      const today = new Date();
      const endDate = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract 1 day from today
      const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 6); // Subtract 6 more days from endDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'next 7 days': {
      const today = new Date();
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Add 1 day to today
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 6); // Add 6 more days to startDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'last 14 days': {
      // Get the current date
      const today = new Date();

      const startDate = new Date(today.getTime() - 1000 * 60 * 60 * 24 * 14);

      // Calculate the end date (yesterday)
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() - 1);

      // Format and return the date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'next 14 days': {
      // Get the current date
      const today = new Date();

      // Calculate the start date (tomorrow)
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24);

      // Calculate the end date (14 days from tomorrow)
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 13);

      // Format and return the date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'this week': {
      const today = new Date();
      const startDate = new Date(today);
      const dayOfWeek = today.getDay();

      // Calculate start of the week (Monday)
      const daysSinceMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Treat Sunday (0) as the end of the week
      startDate.setDate(today.getDate() - daysSinceMonday);

      // Format the dates
      const startDateFormatted = formatDate(startDate);
      const endDateFormatted = formatDate(today);

      return `${startDateFormatted} - ${endDateFormatted}`; // Format and return date range
    }

    case 'last week': {
      // Get the current date
      const today = new Date();

      // Calculate the start date of the previous week (Monday)
      const startDate = new Date(today);
      startDate.setDate(startDate.getDate() - today.getDay() - 6);

      // Calculate the end date of the previous week (Sunday)
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);

      // Return the formatted date range
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }

    case 'this month': {
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Set start date to 1st of current month
      const endDate = formatDate(today);
      return `${formatDate(startDate)} - ${endDate}`; // Format and return date range
    }

    case 'last 30 days': {
      const today = new Date();
      const endDate = new Date(today.getTime() - 1000 * 60 * 60 * 24); // Subtract 1 day to exclude today
      const startDate = new Date(endDate.getTime() - 1000 * 60 * 60 * 24 * 29); // Subtract 29 days from the adjusted endDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'next 30 days': {
      const today = new Date();
      const startDate = new Date(today.getTime() + 1000 * 60 * 60 * 24); // Add 1 day to exclude today
      const endDate = new Date(startDate.getTime() + 1000 * 60 * 60 * 24 * 29); // Add 29 days to the adjusted startDate
      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }

    case 'last month': {
      const firstDayOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfPreviousMonth = new Date(
        firstDayOfCurrentMonth.getTime() - 1000 * 60 * 60 * 24
      ); // Subtract 1 day from the first day of the current month to get the last day of the previous month

      const startDate = new Date(
        lastDayOfPreviousMonth.getFullYear(),
        lastDayOfPreviousMonth.getMonth(),
        1
      ); // Set start date to the first day of the previous month
      const endDate = lastDayOfPreviousMonth; // Set end date to the last day of the previous month

      return `${formatDate(startDate)} - ${formatDate(endDate)}`; // Format and return date range
    }
    default:
      return formatDate(today);
  }
}

function formatDate(date, format = 'D MMM YYYY') {
  // Customizable date format
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return format.replace('D', day).replace('MMM', month).replace('YYYY', year);
}

export const processCompanyName = input => {
  try {
    return input.replace(/[^a-zA-Z\s]/g, '').toUpperCase();
  } catch (error) {
    return '';
  }
};

function isNumericString(value) {
  try {
    if (typeof value === 'string' && /^[0-9]+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return null;
  }
}

const cleanCommas = inputString => {
  // Use regex to replace multiple consecutive commas with a single comma
  return inputString.replace(/,\s*,+/g, ', ').trim();
};

export const processRegisteredOfficeAddress = data => {
  if (!data) return null;
  const {
    registered_office_address: {
      address_line_1: addressOne = '',
      address_line_2: addressTwo = '',
      locality = '',
      postal_code: postalCode = '',
      country = '',
    } = {},
  } = data;

  // Create an array of the address components
  const addressComponents = [
    addressOne,
    addressTwo,
    locality,
    postalCode,
    country,
  ];

  // Filter out empty string values
  const filteredComponents = addressComponents.filter(
    component => component?.trim() !== ''
  );

  // Join the filtered components with ', ' separator
  return cleanCommas(filteredComponents?.join(', '));
};

export const processRegisteredOfficeAddressObject = data => {
  if (!data) return null;
  const {
    registered_office_address: {
      address_line_1: addressOne = '',
      address_line_2: addressTwo = '',
      locality = '',
      // postal_code: postalCode = '',
      country = '',
    } = {},
  } = data;

  return { addressOne: addressOne, addressTwo, locality, country };
};

export const calculateYearsUntilNow = dateString => {
  try {
    const startDate = new Date(dateString);
    const currentDate = new Date();

    // Check if the date is valid
    if (isNaN(startDate)) {
      throw new Error('Invalid date');
    }

    let years = currentDate.getFullYear() - startDate.getFullYear();
    let months = currentDate.getMonth() - startDate.getMonth();
    let days = currentDate.getDate() - startDate.getDate();

    // Adjust the calculation if the current day is earlier in the month than the start day
    if (days < 0) {
      months--;
      days += new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate(); // Get the total days of last month
    }

    // Adjust the calculation if the current month is earlier in the year than the start month
    if (months < 0) {
      years--;
      months += 12; // Add months from a full year
    }

    // Decide the largest unit of time to display
    if (years > 0) {
      return `${years} Year${years > 1 ? 's' : ''} Old`;
    } else if (months > 0) {
      return `${months} Month${months > 1 ? 's' : ''} Old`;
    } else {
      return `${days} Day${days > 1 ? 's' : ''} Old`;
    }
  } catch (error) {
    return '';
  }
};

export const formatNumber = num => {
  if (num === null || num === undefined || isNaN(num)) return 'Unreported';

  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const threshold = 1000;
  let index = 0;

  const isNegative = num < 0;

  num = Math.abs(num);

  while (num >= threshold && index < suffixes.length - 1) {
    num /= threshold;
    index++;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  // If the original number was negative, prepend a minus sign
  return `${isNegative ? '-' : ''}${formatter.format(num)}${suffixes[index]}`;
};

export const formatCurrency = value => {
  // find a better solution for this case
  let strValue = value.toString();

  if (strValue.includes('£') && strValue.startsWith('£-')) {
    strValue = strValue.replace('£-', '-£');
  }

  return strValue;
};

export const calculateNetAssets = (totalAssets, totalLiabilities) => {
  // Handle null, undefined, or non-number inputs for totalAssets and totalLiabilities
  totalAssets = Number(totalAssets) || 0;
  totalLiabilities = Number(totalLiabilities) || 0;

  if (totalAssets === 0 && totalLiabilities === 0) {
    return 'Unreported';
  }

  // Calculate net assets
  const netAssets = totalAssets - totalLiabilities;

  // Use the existing formatNumber function to format the net assets
  return formatNumber(netAssets);
};

export const calculateDebtRatio = (totalAssets, totalLiabilities) => {
  // Handle null, undefined, or non-number inputs for totalAssets and totalLiabilities
  totalAssets = Number(totalAssets) || 0;
  totalLiabilities = Number(totalLiabilities) || 0;

  if (totalAssets === 0) {
    // Prevent division by zero
    return '0.00%';
  }

  // Calculate the debt ratio in percentage
  const debtRatio = (totalLiabilities / totalAssets) * 100;

  // Format the result to two decimal places and append a percent sign
  return formatNumberDepthRatio(debtRatio) + '%';
};

// Example formatting function, adjust as needed
const formatNumberDepthRatio = number => {
  return number.toFixed(2);
};

// handles differencies.
export const formatDifference = data => {
  if (!data || !data.percentage || !data.difference || !data.sign) return '';

  // Extract values from the data object
  const { percentage, difference, sign } = data;

  // Determine color and icon based on sign
  const color =
    sign === 'positive' ? 'green' : sign === 'negative' ? 'red' : 'black';
  const icon =
    sign === 'positive'
      ? faArrowTrendUp
      : sign === 'negative'
        ? faArrowTrendDown
        : null;

  // Return a JSX structure that can be used by the caller
  return (
    <div
      style={{
        fontSize: '10px',
        lineHeight: '1',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon && <FontAwesomeIcon icon={icon} style={{ color: color }} />}
      <div style={{ marginLeft: '3px' }}>
        <div style={{ color }}>{`${difference} (${percentage})`}</div>
        <div style={{ margin: '0', padding: '0' }}>vs previous year</div>
      </div>
    </div>
  );
};

export const findAccountsDataByCompanyNumber = (companyNumber, dataList) => {
  // First, ensure both companyNumber and dataList are provided and valid
  if (!dataList || !companyNumber || !Array.isArray(dataList)) {
    console.error('Invalid inputs provided');
    return {};
  }

  // Find the data object for the specified company number
  const dataObject = dataList.find(
    data => data && data.hasOwnProperty(companyNumber)
  );

  // If the data object is found and it has the property, return the corresponding value
  if (dataObject && dataObject.hasOwnProperty(companyNumber)) {
    return dataObject[companyNumber];
  }

  return {};
};

export const capitalizeFirstLetter = string => {
  try {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  } catch (e) {
    return string;
  }
};

export const statusDiv = status => {
  const statusStyle = getStatusStyle(status);

  return (
    <>
      <div style={statusStyle}>
        {status?.toUpperCase()?.replace('IN', '')?.trim()}
      </div>
    </>
  );
};

export const getStatusStyle = status => {
  let style = {
    borderRadius: '4px',
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'inline-block',
    color: '#fff',
    whiteSpace: 'nowrap',
    background: '',
  };

  if (status === 'active' || status?.includes('active')) {
    style.background = '#16a086';
  } else if (status === 'liquidation') {
    style.background = '#cc5834';
  } else if (status === 'dormant') {
    style.background = '#8c8c8c';
  } else if (status === 'dissolved' || 'd') {
    style.background = '#8B0000';
  } else if (status === 'converted/closed' || 'c') {
    style.background = '#8B0000';
  } else if (status === 'in administration' || status === 'administration') {
    style.background = '#cc5834';
  } else if (status === 'resigned') {
    style.background = 'gray';
  }

  return style;
};

export const getStatusStringFromChar = status => {
  try {
    let lowerS = status?.toLowerCase();
    if (lowerS === 'd') {
      return 'Dissolved';
    }
    if (lowerS === 'c') {
      return 'Converted/Closed';
    }
    return status;
  } catch {
    return '';
  }
};

export const calculateAge = dateObj => {
  try {
    let { day, month, year } = dateObj;

    // Validate the year against the month
    if (year < month) {
      return '';
    }

    // Assign 1 to day or month if they are less than 1
    day = day < 1 ? 1 : day;
    month = month < 1 ? 1 : month;

    // Create the birthDate
    const birthDate = new Date(year, month - 1, day);
    if (isNaN(birthDate.getTime())) {
      throw new Error('Invalid date');
    }

    // Calculate age
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return `${age} years old`;
  } catch (error) {
    return '';
  }
};

export const isVowel = char => {
  return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
};

export const GradientIcon = ({ IconComponent, size = 20, marginRight = 1 }) => (
  <>
    <svg width={0} height={0}>
      <defs>
        <linearGradient id="gradientColors" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stopColor="#0466D1" />
          <stop offset="100%" stopColor="#00D1A9" />
        </linearGradient>
      </defs>
    </svg>

    <IconComponent
      sx={{ fill: 'url(#gradientColors)', fontSize: size, marginRight }}
    />
  </>
);

export const nationalityToFlag = {
  ALANDISLANDS: AlandIslands,
  SAINTBARTHELEMY: SaintBarthelemy,
  BONAIRE: Bonaire,
  CURACAO: Curacao,
  CONGO: Congo,
  IVORYCOAST: IvoryCoast,
  CZECHREPUBLIC: CzechRepublic,
  SOUTHGEORGIAANDSOUTHSANDWICHISLANDS: SouthGeorgiaAndSouthSandwichIslands,
  HEARDANDMCDONALDISLANDS: HeardAndMcDonaldIslands,
  ISLEOFMAN: IsleOfMan,
  BRITISH: UnitedKingdom,
  ASCENSIONISLAND: AscensionIsland,
  ANDORRA: Andorra,
  UNITEDARABEMIRATES: Uae,
  AFGHAN: Afghanistan,
  ANTIGUAANDBARBUDA: AntiguaAndBarbuda,
  ANGUILLA: Anguilla,
  ALBANIA: Albania,
  ARMENIA: Armenia,
  ANGOLA: Angola,
  ANTARCTICA: Antarctica,
  ARGENTINA: Argentina,
  AUSTRIA: Austria,
  AUSTRALIAN: Australia,
  ARUBA: Aruba,
  AZERBAIJAN: Azerbaijan,
  BOSNIAANDHERZEGOVINA: BosniaAndHerzegovina,
  BARBADOS: Barbados,
  BANGLADESHI: Bangladesh,
  BELGIAN: Belgium,
  BURKINAFASO: BurkinaFaso,
  BULGARIAN: Bulgaria,
  BAHRAIN: Bahrain,
  BURUNDI: Burundi,
  BENIN: Benin,
  BERMUDA: Bermuda,
  BRUNEI: Brunei,
  BOLIVIA: Bolivia,
  BRAZIL: Brazil,
  BAHAMAS: Bahamas,
  BHUTAN: Bhutan,
  BOUVETISLAND: BouvetIsland,
  BOTSWANA: Botswana,
  BELARUS: Belarus,
  BELIZE: Belize,
  CANADIAN: Canada,
  COCOSISLANDS: CocosIslands,
  CENTRALAFRICANREPUBLIC: CentralAfricanRepublic,
  SWITZERLAND: Switzerland,
  COOKISLANDS: CookIslands,
  CHILE: Chile,
  CAMEROON: Cameroon,
  CHINESE: China,
  COLOMBIAN: Colombia,
  COSTARICA: CostaRica,
  CUBA: Cuba,
  CAPEVERDE: CapeVerde,
  CHRISTMASISLAND: ChristmasIsland,
  CYPRIOT: Cyprus,
  GERMAN: Germany,
  DJIBOUTI: Djibouti,
  DANISH: Denmark,
  DOMINICA: Dominica,
  DOMINICANREPUBLIC: DominicanRepublic,
  ALGERIA: Algeria,
  ECUADOR: Ecuador,
  ESTONIA: Estonia,
  EGYPTIAN: Egypt,
  WESTERNSAHARA: WesternSahara,
  ERITREAN: Eritrea,
  SPANISH: Spain,
  ETHIOPIA: Ethiopia,
  EUROPEANUNION: EuropeanUnion,
  FINLAND: Finland,
  FIJI: Fiji,
  FALKLANDISLANDS: FalklandIslands,
  MICRONESIA: Micronesia,
  FAROEISLANDS: FaroeIslands,
  FRANCE: France,
  GABON: Gabon,
  UNITEDKINGDOM: UnitedKingdom,
  GRENADA: Grenada,
  ABKHAZIA: Abkhazia,
  SOUTHOSSETIA: SouthOssetia,
  GEORGIA: Georgia,
  FRENCHGUIANA: FrenchGuiana,
  GUERNSEY: Guernsey,
  GHANA: Ghana,
  GIBRALTAR: Gibraltar,
  GREENLAND: Greenland,
  GAMBIA: Gambia,
  GUINEA: Guinea,
  GUADELOUPE: Guadeloupe,
  EQUATORIALGUINEA: EquatorialGuinea,
  GREECE: Greece,
  GUATEMALA: Guatemala,
  GUAM: Guam,
  GUINEABISSAU: GuineaBissau,
  GUYANA: Guyana,
  HONGKONG: HongKong,
  HONDURAS: Honduras,
  CROATIAN: Croatia,
  HAITI: Haiti,
  HUNGARIAN: Hungary,
  CANARYISLANDS: CanaryIslands,
  INDONESIAN: Indonesia,
  IRELAND: Ireland,
  ISRAEL: Israel,
  INDIAN: India,
  BRITISHINDIANOCEANTERRITORY: BritishIndianOceanTerritory,
  IRAQI: Iraq,
  IRANIAN: Iran,
  ICELAND: Iceland,
  ITALIAN: Italy,
  JERSEY: Jersey,
  JAMAICA: Jamaica,
  JORDANIAN: Jordan,
  JAPAN: Japan,
  KENYA: Kenya,
  KYRGYZSTAN: Kyrgyzstan,
  CAMBODIA: Cambodia,
  KIRIBATI: Kiribati,
  COMOROS: Comoros,
  NORTHKOREA: NorthKorea,
  SOUTHKOREA: SouthKorea,
  KUWAITI: Kuwait,
  CAYMANISLANDS: CaymanIslands,
  KAZAKHSTANI: Kazakhstan,
  LAOS: Laos,
  LEBANON: Lebanon,
  SAINTLUCIA: SaintLucia,
  LIECHTENSTEIN: Liechtenstein,
  SRILANKA: SriLanka,
  LIBERIA: Liberia,
  LESOTHO: Lesotho,
  LITHUANIA: Lithuania,
  LUXEMBOURG: Luxembourg,
  LATVIA: Latvia,
  LIBYAN: Libya,
  MOROCCAN: Morocco,
  MONACO: Monaco,
  MOLDOVA: Moldova,
  MONTENEGRO: Montenegro,
  SAINTMARTIN: SaintMartin,
  MADAGASCAR: Madagascar,
  MARSHALLISLANDS: MarshallIslands,
  NORTHMACEDONIA: NorthMacedonia,
  MALI: Mali,
  MYANMAR: Myanmar,
  MONGOLIA: Mongolia,
  MACAU: Macau,
  NORTHERNMARIANAISLANDS: NorthernMarianaIslands,
  MARTINIQUE: Martinique,
  MAURITANIA: Mauritania,
  MONTSERRAT: Montserrat,
  MALTA: Malta,
  MAURITIUS: Mauritius,
  MALDIVES: Maldives,
  MALAWI: Malawi,
  MEXICO: Mexico,
  MALAYSIAN: Malaysia,
  MOZAMBIQUE: Mozambique,
  NAMIBIA: Namibia,
  NEWCALEDONIA: NewCaledonia,
  NIGER: Niger,
  NORFOLKISLAND: NorfolkIsland,
  NIGERIA: Nigeria,
  NICARAGUA: Nicaragua,
  DUTCH: Netherlands,
  NORWAY: Norway,
  NEPALESE: Nepal,
  NAURU: Nauru,
  NIUE: Niue,
  NEWZEALAND: NewZealand,
  OMAN: Oman,
  PANAMA: Panama,
  PERU: Peru,
  FRENCHPOLYNESIA: FrenchPolynesia,
  PAPUANEWGUINEA: PapuaNewGuinea,
  PHILIPPINES: Philippines,
  PAKISTANI: Pakistan,
  POLAND: Poland,
  PITCAIRNISLANDS: PitcairnIslands,
  PUERTORICO: PuertoRico,
  PALESTINIAN: Palestine,
  PORTUGUESE: Portugal,
  PALAU: Palau,
  PARAGUAY: Paraguay,
  QATAR: Qatar,
  REUNION: Reunion,
  ROMANIAN: Romania,
  SERBIAN: Serbia,
  RUSSIAN: Russia,
  RWANDA: Rwanda,
  SAUDIARABIA: SaudiArabia,
  SOLOMONISLANDS: SolomonIslands,
  SEYCHELLES: Seychelles,
  SUDAN: Sudan,
  SWEDEN: Sweden,
  SINGAPORE: Singapore,
  SAINTHELENA: SaintHelena,
  SLOVENIA: Slovenia,
  SLOVAKIA: Slovakia,
  SIERRALEONE: SierraLeone,
  SANMARINO: SanMarino,
  SENEGAL: Senegal,
  SOMALI: Somalia,
  SURINAME: Suriname,
  SOUTHSUDAN: SouthSudan,
  ELSALVADOR: ElSalvador,
  SINTMAARTEN: SintMaarten,
  SYRIA: Syria,
  ESWATINI: Eswatini,
  CHAD: Chad,
  TOGO: Togo,
  THAILAND: Thailand,
  TAJIKISTAN: Tajikistan,
  TOKELAU: Tokelau,
  TIMORLESTE: TimorLeste,
  TURKMENISTAN: Turkmenistan,
  TUNISIA: Tunisia,
  TONGA: Tonga,
  TURKISH: Turkey,
  TUVALU: Tuvalu,
  TAIWANESE: Taiwan,
  TANZANIA: Tanzania,
  UKRAINE: Ukraine,
  UGANDA: Uganda,
  UNITEDSTATESMINOROUTLYINGISLANDS: UnitedStatesMinorOutlyingIslands,
  AMERICAN: UnitedStates,
  URUGUAY: Uruguay,
  UZBEKISTANI: Uzbekistan,
  VATICANCITY: VaticanCity,
  VENEZUELA: Venezuela,
  BRITISHVIRGINISLANDS: BritishVirginIslands,
  VIETNAMESE: Vietnam,
  VANUATU: Vanuatu,
  SAMOA: Samoa,
  KOSOVO: Kosovo,
  YEMENI: Yemen,
  MAYOTTE: Mayotte,
  SOUTHAFRICAN: SouthAfrica,
  ZIMBABWE: Zimbabwe,
  ZAMBIA: Zambia,
};

export const shortenStringByCharCount = (name, charCount) =>
  name.length > charCount ? name.slice(0, charCount) + '...' : name;

export const getOfficerLocationString = address => {
  try {
    const addressParts = [
      address?.premises?.trim() || '',
      address?.address_line_1?.trim() || '',
      address?.address_line_2?.trim() || '',
      address?.locality?.trim() || '',
      address?.region?.trim() || '',
      address?.postal_code?.trim() || '',
      address?.country?.trim() || '',
    ];

    return addressParts.filter(part => part.length > 0).join(', ');
  } catch (error) {
    return '';
  }
};

export const calculateConfirmationStatementOverdueDays = next_due => {
  try {
    const nextDueDate = new Date(next_due);
    const currentDate = new Date();

    // Ensure that we only count full days overdue
    const timeDifference = currentDate - nextDueDate;
    const overdueDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Use Math.floor

    return overdueDays;
  } catch (error) {
    return 0;
  }
};

export const convertGraphToCSV = data => {
  const columns = [
    'officer_name',
    'company_name',
    'occupation',
    'officer_role',
  ];

  // Create CSV headers
  let csvContent = columns.join(',') + '\n';

  // Iterate over edges and match with company data
  data.edges.forEach(edge => {
    const officer = data.nodes.find(node => node.id === edge.source);
    const officerName = officer ? officer.entityName : '';
    const company = data.nodes.find(node => node.id === edge.target);
    const companyName = company ? company.entityName : '';

    // Separate occupation and officer_role combinations into distinct rows
    edge.edgeInfo.occupation.forEach((occupation, index) => {
      const officerRole =
        edge.edgeInfo.officer_role[index] || edge.edgeInfo.officer_role[0];
      // Add CSV row for each occupation-role pair
      csvContent += `${officerName},${companyName},${occupation},${officerRole}\n`;
    });
  });

  return csvContent;
};

export const getEntityNamesFromObjectGraph = dataObject => {
  // Extract the IDs from the combination in the object
  const ids = dataObject?.combination?.ids?.map(idObj => idObj.id) || [];

  // Extract graphs from the dataObject
  const graphs = dataObject?.graphs?.result?.graphs || [];
  // Find nodes matching the extracted IDs
  const entityNames = graphs.flatMap(graph =>
    graph.nodes
      .filter(
        node =>
          ids.includes(node.id) &&
          (node.entityType === 'company' || node.entityType === 'person')
      )
      .map(node => node.entityName)
  );

  let result = '';
  if (entityNames.length === 2) {
    result = entityNames.join(', ');
  } else {
    result = entityNames[0];
  }

  return result;
};

export const convertTimestampToDateTime = timestamp => {
  const milliseconds =
    timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
  const date = new Date(milliseconds);

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  // Extract hours, minutes, and seconds
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Return the formatted date and time
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const openGoogleMaps = address => {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

  // Open new tab with the Google Maps URL
  window.open(googleMapsUrl, '_blank');
};

export const getNodeNameById = (listOfNodes, ids, makeshort = false) => {
  if (listOfNodes) {
    const isMultiId = ids.length > 1;
    if (!isMultiId) {
      const node = listOfNodes.find(item => item.id === ids[0]);
      return makeshort
        ? shortenStringByCharCount(node?.entityName, 5)
        : node?.entityName;
    }

    const nodeOneName = listOfNodes.find(
      item => item.id === ids[0]
    )?.entityName;
    const nodeTwoName = listOfNodes.find(
      item => item.id === ids[1]
    )?.entityName;

    return makeshort
      ? `${shortenStringByCharCount(nodeOneName, 5)} and ${shortenStringByCharCount(nodeTwoName, 5)}`
      : `${nodeOneName} and ${nodeTwoName}`;
  }
};

export const isValidJSON = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const forMateDateIntoEnglish = dateStr => {
  if (dateStr === '' || dateStr === undefined) return 'Unreported';
  const date = new Date(dateStr);
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};
