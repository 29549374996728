import React, { useEffect, useState } from 'react';
import ProbLabel from '../../../components/ProbLabel/ProbLabel';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import AddIcon from '@mui/icons-material/Add';
import usePageTitle from '../../../context/PageContext/PageContext.js';
import CESearchEntitiesSection from './CESearchEntitiesSearchSection';
import ExploreLinksOfficerUpdatedCard from '../../../components/ExploreLinksOfficerUpdatedCard/ExploreLinksOfficerUpdatedCard';
import '../../CorporateLinks/CESearchEntities/CESearchEntitiesFlowSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const CESearchEntitiesFlowSection = ({
  searchContainerRef,
  inputValue,
  handleInputFocus,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  removeItem,
  itemClicked,
  setSelectedType,
  hideButtonsAndSearch,
  setHideButtonsAndSearch,
  setSuggestions,
  setIsOpen,
  totalOfficersAndCompanies,
}) => {
  const pageTitle = usePageTitle();
  const [selectEntityTypeButtonList] = useState(['People', 'Companies']);
  const [isSearchSectionEnabled, setIsSearchSectionEnabled] = useState(false);

  const [isCESearchOnlyVisible, setIsCESearchOnlyVisible] = useState(true);

  const handleInputFocusChild = () => {
    setIsCESearchOnlyVisible(false);
  };



  const handleSelectEntity = type => {
    handleButtonClick(type);
    setIsSearchSectionEnabled(true);
  };

  const selectEntityType = () => {
    return (
      <div className="corporate-links-flow-section-add-entity-button-holder">
        {selectEntityTypeButtonList.map(item => {
          const isSelected = item === selectedType;
          return (
            <ProbeButton
              key={item}
              width={'150px'}
              height={'40px'}
              name={item}
              fontColor={isSelected ? '#FFFFFF' : '#000000'}
              backgroundColor={isSelected ? 'linear-gradient(to bottom right, #0466D1, #00D1A9)' : '#CFCFCF'}
              borderColor={'#CFCFCF'}
              borderRadius={'20px'}
              iconSrc={<AddIcon />}
              onClick={() => handleSelectEntity(item)}
              disabled={item === '...' ? true : false}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="cesearch-entites-container">
      {isCESearchOnlyVisible && ( // Hide this section when input is focused
        <div className="cesearch-entites-main-container">
          <div>
            <h2 className="text-black">{pageTitle}</h2>
            <ProbLabel text="Select people or companies, search to add them to your" />
            <ProbLabel text="corporate links graph, and click 'Create Network' when finished" />
          </div>
          <div className="corporate-links-flow-section-add-entity">
            {selectEntityType()}
          </div>
        </div>
      )}
      <div className={isCESearchOnlyVisible ? 'cesearch-entites-main-container' : 'container'}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isCESearchOnlyVisible ? "left" : "center",
            marginTop: "10px",
            gap: "16px",
          }}
        >
          <CESearchEntitiesSection
            setIsCESearchOnlyVisible={setIsCESearchOnlyVisible}
            isCESearchOnlyVisible={isCESearchOnlyVisible}
            searchContainerRef={searchContainerRef}
            inputValue={inputValue}
            handleInputFocus={handleInputFocusChild}
            handleInputBlur={handleInputBlur}
            handleChange={handleChange}
            selectedSuggestions={selectedSuggestions}
            handleGraphPage={handleGraphPage}
            isOpen={isOpen && isSearchSectionEnabled}
            selectedType={selectedType}
            handleButtonClick={handleButtonClick}
            isLoadingPage={isLoadingPage}
            loadingGif={loadingGif}
            suggestions={suggestions}
            removeItem={removeItem}
            itemClicked={itemClicked}
            showCards={false}
            totalOfficersAndCompanies={totalOfficersAndCompanies}
            disabled={(!selectedType || selectedSuggestions.length == 2) ? true : false}
            startIcon={<FontAwesomeIcon icon={faSearch} />}
          />
          <div>
            {isCESearchOnlyVisible && (
              <ProbeButton
                width={'235px'}
                height={'40px'}
                borderRadius={'20px'}
                marginRight={'32px'}
                name="Advance Search"
                fontColor={!selectedType ? '#000000' : '#FFFFFF'}
                backgroundColor={!selectedType ? '#CFCFCF' : 'linear-gradient(to bottom right, #0466D1, #00D1A9)'}
                disabled={!selectedType ? 'linear-gradient(to bottom right, #0466D1, #00D1A9)' : '#CFCFCF'}
              />
            )}
          </div>
        </div>
        {isCESearchOnlyVisible && ( // Hide this section when input is focused
          <div className="ec-search-entites-explore-link-officer-card-main">
            <div>
              <div className="ec-search-entites-explore-link-officer-card">
                {selectedSuggestions &&
                  selectedSuggestions?.map(sugg => (
                    <div key={sugg.id}>
                      <ExploreLinksOfficerUpdatedCard
                        data={sugg}
                        removeItem={removeItem}
                        showTitle={false}
                      />
                    </div>
                  ))}
              </div>
              <div className="cesearch-entites-footer">
                <ProbeButton
                  width="343px"
                  height="40px"
                  name="Create Network"
                  borderRadius="20px"
                  backgroundColor={selectedSuggestions == 0 ? '#CFCFCF' : 'linear-gradient(to right, #0466D1, #00D1A9)'}
                  fontColor="white"
                  borderColor="#CFCFCF"
                  disabled={selectedSuggestions == 0 ? true : false}
                  iconSrc={<AddIcon />}
                  onClick={handleGraphPage}
                />
              </div>
            </div>
          </div>
        )}
        <div>
          {isCESearchOnlyVisible && (<hr />)}
        </div>

      </div>
    </div >
  );
};

export default CESearchEntitiesFlowSection;
