import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilter.css';
import DateRangeSlider from './CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';

const CESAdvancedFilter = () => {
  const minDate = 1800;
  const maxDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);
  const [filters, setFilters] = useState({
    companyStatus: {
      active: false,
      dissolved: false,
    },
    companyType: {
      ltd: false,
      Partnership: false,
    },
    locality: '',
    dateRange: [minDate, maxDate],
  });

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      companyStatus: {
        ...prevFilters.companyStatus,
        [name]: checked,
      },
      companyType: {
        ...prevFilters.companyType,
        [name]: checked,
      },
    }));
  };

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDateRangeChange = newRange => {
    setFilters(prevFilters => ({
      ...prevFilters,
      dateRange: newRange,
    }));
  };

  useEffect(() => {
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: null,
      companyFilters: filters,
    });
  }, [filters]);

  return (
    <div className="company-advance-filter-container">
      <div className="row">
        <div className="col-md-4">
          <label for="companyName" class="form-label filter-label">Company Name</label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="companyName"
            placeholder="Company Name"
            name="companyName" />
        </div>
        <div className="col-md-4">
          <label for="companyNumber" class="form-label filter-label">Company Number</label>
          <input
            className="form-control ompany-person-advance-filter-form-control"
            id="companyNumber"
            placeholder="Company Number"
            name="companyNumber" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label for="locality" class="form-label filter-label">Nationality:</label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="locality"
            name="locality"
            value={filters?.locality}
            onChange={handleSelectChange}
          >
            <option value="" selected disabled>Nationality</option>
            <option>Chesterfield</option>
            <option>Grimsby</option>
            <option>Milton Keynes</option>
            <option>London</option>
            <option>Leamington SPA</option>
            <option>Manchester</option>
            <option>Reading</option>
          </select>
        </div>
        <div className="col-md-4">
          <label for="postalCode" class="form-label filter-label">Postal Code</label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postalCode"
            placeholder="Post Code"
            name="postalCode" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-2">
          <label htmlFor="company-status" className="form-label filter-label">Company Status:</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="active"
              name="active"
              checked={filters?.companyStatus?.active}
              onChange={handleCheckboxChange}
            />
            <label className="company-person-advance-filter-form-check-label" htmlFor="active">
              Active
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="dissolved"
              name="dissolved"
              checked={filters?.companyStatus?.dissolved}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="dissolved">
              Dissolved
            </label>
          </div>
        </div>
        <div className="col-md-2">
          <label htmlFor="companyType" className="form-label filter-label">Company Type:</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="ltd"
              name="ltd"
              checked={filters.companyType.ltd}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="ltd">
              ltd.
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="Partnership"
              name="Partnership"
              checked={filters.companyType.Partnership}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label" htmlFor="Partnership">
              Partnership
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <DateRangeSlider
            labelName={'Date of creation Range'}
            values={filters.dateRange}
            setValues={handleDateRangeChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilter;
